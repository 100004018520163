.language-flags
  .language-flags__one
    display: inline-block
    text-decoration: none
    color: $c_black
    text-align: center
    line-height: 20px
    padding-left: $paddingBase*1.5
    .language-flags__one-text
      display: block
      font-weight: bold
    svg
      width: 35px
      height: auto
      transition: .2s
      box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px
    &:hover
      color: $vivaGreenDark
      svg
        transform: scale(1.1)

.headline-with-flags,
.breadcrumb-with-language-flags
  position: relative
  .language-flags
    position: absolute
    top: 50%
    right: 0
    transform: translate(0, -50%)

@include responsive-breakpoint-min($secondBreakpoint)
  .breadcrumb-with-language-flags
    .breadcrumb
      //width: calc(100% - 105px)
      width: calc(100% - 85px)

.headline-with-flags,
.breadcrumb-with-language-flags
  @include second-breakpoint
    .language-flags
      display: none

.menu-language-flag-container
  display: none
  @include second-breakpoint
    display: block
    border-top: 1px solid rgba(127, 188, 73, 0.5)
    padding: $paddingBase*2 0 $paddingBase 0
    text-align: center
    .language-flags
      .language-flags__one
        padding: 0 $paddingBase*1.5


.headline-bazar
  line-height: 20px

  @include second-breakpoint
    font-size: 20px
    line-height: 30px

  &__additional-text
    display: block
    text-align: center

// ---------------------------

.language-drop-down-selector
  $this: &
  $width: 80px
  $flagWidth: 40px
  $flagHeight: 30px
  $boxShadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px
  position: relative
  width: $width
  @include second-breakpoint
    display: none

  @keyframes growDown
    0%
      transform: scaleY(0)
    80%
      transform: scaleY(1.1)
    100%
      transform: scaleY(1)

  &--list
    display: none
    //padding-top: $paddingBase
    //box-shadow: $boxShadow
    position: absolute
    z-index: 5
    width: $width


  &--actual
    padding: $paddingBase
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer


    span
      display: block
      text-align: center
      font-weight: bold
      padding-top: $paddingBase*0.5

    svg
      display: block
      box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px

    &--flag
      svg
        width: $flagWidth
        height: $flagHeight

  &:hover
    box-shadow: $boxShadow

    #{$this}--actual
      background: rgba(255, 255, 255, .4)

    #{$this}--list
      display: block
      animation: growDown 300ms ease-in-out forwards
      transform-origin: top center
      background: $c_white
      box-shadow: $boxShadow

  .language-selector-list

    &--item
      text-align: center
      transition: 400ms

      &:hover
        background: rgba(0, 0, 0, .2)

      span
        display: block
        text-align: center
        font-weight: bold
        padding-top: $paddingBase*0.5

      a
        display: block
        padding: $paddingBase*1.75 $paddingBase $paddingBase $paddingBase
        color: $c_black
        text-decoration: none

      //&:last-of-type
        a
          padding-bottom: $paddingBase

      //&:first-of-type
        a
          padding-top: $paddingBase

      svg
        width: $flagWidth
        height: $flagHeight
        display: block
        margin: 0 auto
        box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px

.language-drop-down-selector-slogan
  .language-drop-down-selector--actual
    padding: 3px

  .language-selector-list
    a
      padding-bottom: $paddingBase*0.5

.language-drop-down-selector-breadcrumb,
.language-drop-down-selector-homepage
  position: absolute
  right: 0
  top: 50%
  transform: translate(0, -50%)
  z-index: 2
