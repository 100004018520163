.list-of-text-pages
  display: flex
  flex-wrap: wrap
  margin: $paddingBase*3 0 $paddingBase*6 0
  .list-of-text-pages__item
    padding: $paddingBase*1.5
    width: 50%
    background: $c_gray8
    .list-of-text-pages__item--content
      text-align: justify
    &:first-child, &:last-child
      background: $c_gray7
    @include third-breakpoint
      width: 100%
    h3
      padding-bottom: $paddingBase
      font-size: 32px
      line-height: 38px
      a
        text-decoration: none
        &:hover
          text-decoration: underline