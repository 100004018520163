@mixin footerDarContainerButtonDefault()
  @include buttonDefault()
  display: block
  border-radius: 0
  box-shadow: none
  font-weight: bold
  text-transform: uppercase
  &:hover
    box-shadow: 0 5px 7px 0 rgba(160, 160, 160, 0.5)

@mixin footerDarContainerItemButtonSameHeight()
  @include responsive-breakpoint-min($firstBreakpoint)
    min-height: 340px
    position: relative
    padding-bottom: 40px
    .link
      padding-top: 0
      position: absolute
      bottom: 0
      width: 100%

.footer-dark-container
  background: $c_gray9
  padding: $paddingBase*4 0
  h3
    padding-bottom: $paddingBase*1.5
  .link
    padding-top: $paddingBase
    a
      @include footerDarContainerButtonDefault()
  *
    color: $c_white
  .inner
    display: flex
    justify-content: space-between
    @include second-breakpoint
      flex-wrap: wrap
      justify-content: center
  .footer-catalog-item
    width: 42%
    @include footerDarContainerItemButtonSameHeight()
    @include second-breakpoint
      width: 100%
    .content
      display: flex
      > div
        width: 50%
      dl
        padding-left: $paddingBase*2
        display: flex
        flex-wrap: wrap
        dt
          font-weight: bold
        dt, dd
          width: 50%
          padding-bottom: $paddingBase
          line-height: 24px
  .footer-article
    width: 50%
    @include footerDarContainerItemButtonSameHeight()
    @include second-breakpoint
      width: 100%
      padding-bottom: $paddingBase*3
    h3
      a
        text-decoration: none
        color: $c_white
        &:hover
          color: $c_white
          text-decoration: underline
    .content
      @include addClearFix()
      img
        float: left
        padding-right: $paddingBase*1.5
        padding-bottom: $paddingBase*1.5
      .date
        font-size: 13px
        line-height: 18px
        padding: $paddingBase 0
        margin-bottom: $paddingBase
        display: block
        &:before
          @include addFasIcon($fa-var-calendar)
          font-size: 18px
          margin-right: $paddingBase*0.5
          position: relative
          top: -2px
