.catalog-detail-content
  $paddingForRightContent: $paddingBase*3
  padding-bottom: $paddingBase*3
  h1
    color: $vivaGreenDark
    padding-bottom: $paddingBase*3

  .catalog-detail__content
    display: flex
    //padding: 0 $paddingBase*3 0 0
    @include second-breakpoint
      flex-wrap: wrap
    > div
      width: 50%
      @include second-breakpoint
        width: 100%
      &.catalog-detail__content--image-description
        .catalog-detail__content--image
          span
            display: inline-block
            position: relative
            img
              display: block
            &.no-image
              width: 500px
              height: 380px
              display: flex
              justify-content: center
              align-items: center
            &:not(.no-image):hover
              @include zoomIcon()
            .catalog-detail__content--brand-logo
              position: absolute
              top: $paddingBase*2
              right: $paddingBase*2
        .catalog-detail__content--description
          padding: $paddingBase*2 0
      &.catalog-detail__content--parameters
        padding-left: $paddingForRightContent
        @include second-breakpoint
          padding-left: 0
        dl
          display: flex
          flex-wrap: wrap
          dt
            width: 40%
            font-weight: bold
            &.price
              text-transform: uppercase
          dd
            width: 60%
            &.info-icon-before
              position: relative
              img.info-icon-before--icon
                position: absolute
                height: 20px
                width: auto
                top: 2px
                left: -30px
          dt,dd
            &.price
              color: $vivaGreenDark
              font-size: 20px
              line-height: 30px
              font-weight: bold
              padding-top: $paddingBase*1.5
              &.gray
                color: $c_gray12
              &.small
                font-size: 16px
                line-height: 20px
                font-weight: normal

  .parameter-with-information-container
    display: flex
    flex-wrap: wrap
    .parameter-with-information
      padding-right: $paddingBase
      padding-bottom: $paddingBase
      position: relative
      @include third-breakpoint
        width: 100%
        padding-right: 0
        padding-bottom: $paddingBase*0.5
      &--button
        display: flex
        align-items: center
        color: $vivaGreenDark
        text-decoration: underline
        transition: 300ms
        &:hover
          color: $vivaYellow
          cursor: help
      &--text
        position: absolute
        background: $c_white
        padding: $paddingBase
        display: none
        bottom: 150%
        width: 500px
        left: 50%
        transform: translate(-50%, 0)
        border-radius: 2px
        text-align: center
        font-size: 15px
        //box-shadow: rgba(0, 0, 0, 0.15) 0 5px 15px 0
        box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px
        @include third-breakpoint
          width: 100%
          &.open
            display: block
            padding: $paddingBase*3 $paddingBase
            &:after
              @include addFasIcon($fa-var-times)
              position: absolute
              right: 10px
              top: 10px
              color: $c_red
        &:before
          $size: 15px
          top: 99%
          content: ' '
          position: absolute
          left: 50%
          transform: translate(-50%, 0)
          width: 0
          height: 0
          border-left: $size solid transparent
          border-right: $size solid transparent
          border-top: $size solid $c_white
          @include third-breakpoint
            left: 5%
            transform: translate(0, 0)
      &:hover
        .parameter-with-information--text
          display: block
  .catalog-detail__bottom-text
    background: $c_gray20
    padding: $paddingBase*2
  .catalog-detail__info-line
    //padding: $paddingBase*2 $paddingBase*1.5
    padding: $paddingBase*2 0
    @include smallest-view
      padding-right: 0
      padding-left: 0
    .catalog-detail__content--parameters
      dl
        dt, dd
          &.price
            padding-top: 0 !important
        @include full-view
          position: relative
          top: -6px
      @include second-breakpoint
        order: 1
        padding-bottom: $paddingBase*2
      @include third-breakpoint
        padding-left: 0 !important
      @include smallest-view
        dl
          dd.price
            text-align: right
          dt, dd
            &.price
              font-size: 25px
    .catalog-detail__info-line--parameters-icons
      display: flex
      @include second-breakpoint
        order: 2
        flex-wrap: wrap
      @include smallest-view
        justify-content: space-between
      .catalog-detail__info-line--parameters-icons--one
        font-weight: bold
        color: $c_gray22
        text-transform: uppercase
        width: 100px
        //padding-right: $paddingBase*3
        text-align: center
        @include third-breakpoint
          width: 33%
          margin: $paddingBase*2 0
        //&:first-of-type
          margin-left: 5px
          padding-right: 28px
        //&:nth-of-type(2)
          padding-right: 26px
        img
          height: 45px
          display: block
          margin: 0 auto 2px auto
        //&.load-capacity, &.lift
         img
           height: 52px
  .catalog-detail__tool-line
    display: flex
    align-items: center
    background: $c_gray20
    //padding: 0 $paddingBase*2
    @include second-breakpoint
      flex-wrap: wrap
      margin: $paddingBase*2 0
      padding-left: $paddingBase*2
      padding-right: $paddingBase*2
    .catalog-detail__tool-line--one
      padding: $paddingBase*2 0
      &.pdf, &.share
        width: 100px
        text-align: center
        a
          justify-content: center
      &.phone
        padding-left: 29px
        width: 255px
      &.phone, &.email
        flex-grow: 1
        font-weight: bold
        font-size: 19px
        img
          margin-right: $paddingBase*2
      a
        display: flex
        align-items: center
        text-decoration: none
        color: $c_gray21
      img
        width: auto
        height: 50px
      @include second-breakpoint
        &.pdf, &.share, &.compare, &.phone, &.email
          flex-grow: 0
          padding: $paddingBase*2 0

        &.pdf, &.share, &.compare
          width: 33%
          display: flex
          justify-content: center

        &.phone, &.email
          width: 50%
      @include third-breakpoint
        &.pdf, &.share, &.compare
          width: 33%
        &.phone, &.email
          width: 100%
  .catalog-detail__ask-for-demand
    text-align: right
    font-size: 19px
    line-height: 40px
    a
      text-decoration: none
      transition: 300ms
      &:hover
        text-decoration: underline
        color: $vivaYellow
    img
      vertical-align: middle
      margin-right: $paddingBase*1.5
    button
      @include buttonDefaultForButtonEl()
      font-weight: bold
      font-size: 20px
      line-height: 30px
      margin-top: 0
      padding: $paddingBase $paddingBase*2
      text-transform: uppercase
  .catalog-detail__headline
    display: flex
    align-items: center
    @include second-breakpoint
      flex-wrap: wrap
    > div
      width: 50%
      @include second-breakpoint
        width: 100%
      &.catalog-detail__headline--share-print
        padding-left: $paddingForRightContent
        @include second-breakpoint
          padding-left: 0
        display: flex
        > div
          width: 50%
        a
          display: inline-block
  &.is-archive
    h1
      color: $c_gray4
    .gallery-list
      .gallery-item
        span
          img
            filter: grayscale(100%)
    .catalog-detail__content
      > div
        &.catalog-detail__content--image-description
          .catalog-detail__content--image
            span
              position: relative
              em
                color: $c_red
                font-style: normal
                position: absolute
                z-index: 3
                left: 50%
                top: 50%
                transform: translate(-50%, -50%) rotate(-20deg)
                display: block
                border: 5px solid $c_red
                font-size: 63px
                line-height: normal
                padding: $paddingBase
                text-transform: uppercase
            img
              filter: grayscale(100%)
.catalog-detail-arhive-information
  background: $vivaYellow
  border-radius: $baseBorderRadius
  padding: $paddingBase*1.5 $paddingBase*3 $paddingBase*1.5 $paddingBase*10
  position: relative
  &:before
    @include addFasIcon($fa-var-exclamation-triangle)
    position: absolute
    left: $paddingBase*3
    top: 50%
    font-size: 32px
    transform: translate(0, -50%)
  .headline
    font-size: $fontSizeBase + 10
    line-height: $lineHeightBase + 10
.catalog-detail-arhive-information__show-more-link
  text-align: center
  padding-bottom: $paddingBase*3
  a
    @include buttonDefault()
    margin: 0


.catalog-detail__content--carousel
  .splide__arrows
    .splide__arrow
      background: none
      &:hover
        background: $c_white
  .splide__pagination__page
    $size: 10px
    width: $size
    height: $size
    &.is-active
      transform: none
      background: $c_white
      opacity: .9
  .catalog-detail__content--carousel__item
    &.youtube-item
      display: flex
      justify-content: center
      align-items: center
    .bazar-youtube
      display: block
      width: 100%
      margin: 0 auto
      max-width: 100%
      iframe
        width: 100% !important
        height: 100%
        min-height: 270px
    a
      display: block
      text-align: center