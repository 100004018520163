#clockContainer
  /* adding a clock frame
  background: url('/assets/images/clock.png') no-repeat
  position: relative
  height: 150px
  width: 150px
  background-size: 100%
  margin: auto
  #hour
    position: absolute
    background: black
    border-radius: 25px
    transform-origin: bottom
    width: 1.8%
    height: 25%
    top: 25%
    left: 48.85%
  #minute
    position: absolute
    background: black
    border-radius: 25px
    transform-origin: bottom
    width: 1.6%
    height: 30%
    top: 19%
    left: 48.9%
  #second
    position: absolute
    background: red
    border-radius: 25px
    transform-origin: bottom
    width: 1%
    height: 40%
    top: 9%
    left: 49.25%