.breadcrumb
  margin: $paddingBase*2 0
  padding: $paddingBase*1.5 $paddingBase*2
  background: $c_gray10
  border-radius: $baseBorderRadius
  ol
    display: flex
    flex-wrap: wrap
    list-style: none
    @include first-breakpoint
      flex-wrap: nowrap
      overflow-x: auto
      white-space: nowrap
    li
      color: $c_gray2
      &:before
        content: '/'
        color: $c_black
        padding-left: $paddingBase
        padding-right: $paddingBase
      &.breadcrumb__homepage
        &:before
          display: none
      a
        text-decoration: none
        transition: .3s
        line-height: $lineHeightBase
        @include responsive-breakpoint-min($firstBreakpoint)
          display: block
        &:hover
          text-decoration: underline
          color: $vivaYellow
        @include responsive-breakpoint-min($secondBreakpoint)
          float: right
          max-width: 140px
          text-overflow: ellipsis
          white-space: nowrap
          overflow: hidden
          &:hover
            max-width: none