@font-face
  font-family: 'barlow'
  src: url($dirFonts + 'barlow-bold-webfont.eot')
  src: url($dirFonts + 'barlow-bold-webfont.eot?#iefix') format("embedded-opentype"), url($dirFonts + 'barlow-bold-webfont.woff2') format("woff2"), url($dirFonts + 'barlow-bold-webfont.woff') format("woff")
  font-weight: bold
  font-style: normal

@font-face
  font-family: 'barlow'
  src: url($dirFonts + 'barlow-italic-webfont.eot')
  src: url($dirFonts + 'barlow-italic-webfont.eot?#iefix') format("embedded-opentype"), url($dirFonts + 'barlow-italic-webfont.woff2') format("woff2"), url($dirFonts + 'barlow-italic-webfont.woff') format("woff")
  font-weight: normal
  font-style: italic

@font-face
  font-family: 'barlow'
  src: url($dirFonts + 'barlow-regular-webfont.eot')
  src: url($dirFonts + 'barlow-regular-webfont.eot?#iefix') format("embedded-opentype"), url($dirFonts + 'barlow-regular-webfont.woff2') format("woff2"), url($dirFonts + 'barlow-regular-webfont.woff') format("woff")
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'montserrat'
  src: url($dirFonts + 'montserrat-bold-webfont.eot')
  src: url($dirFonts + 'montserrat-bold-webfont.eot?#iefix') format("embedded-opentype"), url($dirFonts + 'montserrat-bold-webfont.woff2') format("woff2"), url($dirFonts + 'montserrat-bold-webfont.woff') format("woff")
  font-weight: bold
  font-style: normal

@font-face
  font-family: 'montserrat'
  src: url($dirFonts + 'montserrat-regular-webfont.eot')
  src: url($dirFonts + 'montserrat-regular-webfont.eot?#iefix') format("embedded-opentype"), url($dirFonts + 'montserrat-regular-webfont.woff2') format("woff2"), url($dirFonts + 'montserrat-regular-webfont.woff') format("woff")
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'rajdhani'
  src: url($dirFonts + 'rajdhani-regular-webfont.eot')
  src: url($dirFonts + 'rajdhani-regular-webfont.eot?#iefix') format("embedded-opentype"), url($dirFonts + 'rajdhani-regular-webfont.woff2') format("woff2"), url($dirFonts + 'rajdhani-regular-webfont.woff') format("woff")
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'rajdhani'
  src: url($dirFonts + 'rajdhani-medium-webfont.eot')
  src: url($dirFonts + 'rajdhani-medium-webfont.eot?#iefix') format("embedded-opentype"), url($dirFonts + 'rajdhani-medium-webfont.woff2') format("woff2"), url($dirFonts + 'rajdhani-medium-webfont.woff') format("woff")
  font-weight: 500
  font-style: normal

@font-face
  font-family: 'rajdhani'
  src: url($dirFonts + 'rajdhani-bold-webfont.eot')
  src: url($dirFonts + 'rajdhani-bold-webfont.eot?#iefix') format("embedded-opentype"), url($dirFonts + 'rajdhani-bold-webfont.woff2') format("woff2"), url($dirFonts + 'rajdhani-bold-webfont.woff') format("woff")
  font-weight: bold
  font-style: normal