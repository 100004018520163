.catalog-list-content
  .catalog-list-content__footer
    padding-bottom: $paddingBase*3
  .loading-forklift-ride
    margin-bottom: $paddingBase*3
  .catalog-infinite-scroll
    //background: #00a2e8
    visibility: hidden
    height: $paddingBase*0.5
  h1
    color: $vivaGreenDark
    padding: $paddingBase*3 0
  @import "catalog-list-filters"
  @import "catalog-list-sorter"
  .catalog-list-of-items
    padding: $paddingBase*3 0
    display: flex
    flex-wrap: wrap
    flex-grow: 0
    margin: 0 $paddingBase*1.5*-1
    @include smallest-view
      margin: 0
    .item
      width: 25%
      padding: 0 $paddingBase*1.5
      margin-bottom: $paddingBase*2
      //@include full-view
        //&:nth-child(4n+1)
        //  padding-left: 0
        //&:nth-child(4n)
          padding-right: 0
      @include first-breakpoint
        width: 33.3%
      //@include from-first-to-full-breakpoint
        //&:nth-child(3n+1)
        //  padding-left: 0
        //&:nth-child(3n)
        //  padding-right: 0
      @include second-breakpoint
        width: 50%
        //&:nth-child(2n+1)
        //  padding-left: 0
        //&:nth-child(2n)
        //  padding-right: 0
      @include smallest-view
        width: 100%
        padding-left: 0
        padding-right: 0
      //*
        font-family: $fontFamilyRajdhani
      .item__content
        min-height: 580px

        @include first-breakpoint
          min-height: 630px

        @include smallest-view
          min-height: 660px

        overflow: hidden
        //box-shadow: 0 0 12px 6px $c_gray7
        border: 1px solid $c_gray23
        transition: 300ms
        position: relative
        width: 100%
        &:hover
          border-color: $c_gray11
          box-shadow: 0 0 20px 5px $c_gray11
        a
          display: block
          text-decoration: none
          &:hover
            text-decoration: none
        h2
          padding: 0 $paddingBase*1.5
          min-height: 50px
          a
            color: $c_gray12
            font-weight: bold
            font-size: 19px
            line-height: 23px
        .item__price
          padding: 0 $paddingBase*1.5 $paddingBase*3 $paddingBase*1.5
          display: block
          width: 100%
          color: $c_gray13
          position: absolute
          bottom: $paddingBase*3
          &--row
            $this: &

            display: flex
            &--column
              width: 50%
              line-height: 30px
              &.right
                text-align: right
              &:first-child
                font-weight: bold
                font-size: 20px
            &.small
              .item__price--row--column
                font-size: 16px
                line-height: 20px
                color: $c_gray12
                &:first-child
                  font-weight: normal

        .item__image
          display: block
          text-align: center
          padding: 0 0 $paddingBase*3 0
          img
            display: block
            margin: 0 auto
            padding: 0
          .item__image--inner
            display: block
            min-height: 190px
          .no-image
            padding: 7px $paddingBase*2
            display: inline-block
            border-bottom: 1px solid #e2e2e2
            //border: 1px solid $c_gray19
        .item__brand-image
          display: block
          padding: $paddingBaseRem*0.5 $paddingBase*1.5 $paddingBaseRem*0.5 $paddingBase*1.5
          img
            display: block
            margin: 0
            padding: 0
          .item__brand-image--no-image
            min-height: 25px
            display: block
        .item__list-parameters
          padding: 0 $paddingBase*1.5 $paddingBase*3 $paddingBase*1.5
          display: block
          .item__list-parameters--row
            display: flex
            .item__list-parameters--column
              width: 50%
              &:first-child
                font-weight: bold
            &.price
              .item__list-parameters--column
                line-height: 30px
                &:first-child
                  font-size: 20px
          *
            color: $c_gray13
        .item__button
          position: absolute
          bottom: 0
          width: 100%
          display: block
          background: $vivaGreenDark
          color: $c_white
          text-align: center
          box-shadow: 0 5px 7px 0 rgba(160, 160, 160, 0.5)
          font-family: $fontFamilyMontserrat
          font-weight: bold
          text-transform: uppercase
          transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
          padding: $paddingBaseRem*0.5 0
          &:hover
            background: $vivaYellow
    &.small-version
      .item
        .item__content
          min-height: 370px
          .item__image
            padding-left: $paddingBase*1.5
            padding-right: $paddingBase*1.5
.catalog-list-empty
  margin-top: $paddingBase*3
  background: $vivaYellow
  border-radius: $baseBorderRadius
  padding: $paddingBase*1.5 $paddingBase*3 $paddingBase*1.5 $paddingBase*10
  position: relative
  &:before
    @include addFasIcon($fa-var-exclamation-triangle)
    position: absolute
    left: $paddingBase*3
    top: 50%
    font-size: 32px
    transform: translate(0, -50%)
  .headline
    font-size: $fontSizeBase + 10
    line-height: $lineHeightBase + 10
.catalog-list-empty__link-more-items
  text-align: center
  a
    @include buttonDefault()
    margin: 0
    padding: $paddingBase $paddingBase*1.5