.two-columns
  display: flex
  flex-wrap: wrap
  padding-top: $paddingBase*1.5
  > div
    width: 50%
    @include third-breakpoint()
      width: 100%
    a
      text-decoration: none
      &:hover
        color: $vivaGreenDark
        text-decoration: underline
    padding: $paddingBase*1.5
    &:first-child
      background-color: $c_gray7
  &.switch
    > div
      &:first-child
        background-color: transparent
      &:last-child
        background-color: $c_gray7