@mixin baseContentStyle()
  img
    max-width: 100%
    height: auto
  h1
    color: $vivaGreenDark
    font-weight: bold
    padding-bottom: $paddingBase*3
  h2
    color: $vivaGreenDark
    padding-bottom: $paddingBase
  p
    padding-top: $paddingBase*1.5
    padding-bottom: $paddingBase*1.5
    text-align: justify
  hr
    margin-top: $paddingBase*1.5
    margin-bottom: $paddingBase*1.5
    border: none
    border-top: 1px solid $c_lightGreen
  ul
    list-style: disc
    margin-block-start: $paddingBase*1.5
    margin-block-end: $paddingBase*1.5
    margin-inline-start: 0
    margin-inline-end: 0
    padding-inline-start: $paddingBase*4
  table
    width: 100%
    border: 1px solid $c_gray14
    border-spacing: 2px
    tr
      th, td
        padding: $paddingBaseRem*0.5
        border: 1px solid $c_gray14
      th
        text-align: left
        border-bottom-width: 2px
    thead
      tr
        th
          background: $c_gray16
          border-color: $c_gray17