.catalog-filters
  background: $c_gray10
  padding: $paddingBase $paddingBase*3
  @include third-breakpoint
    padding-left: $paddingBase*1.5
    padding-right: $paddingBase*1.5
  form
    $border: 1px solid rgba(63, 174, 41, 0.5)
    $paddingForm: $paddingBase*0.5 $paddingBase
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    .joined-element-container
      width: 25%
      padding: 0 $paddingBase*1.5
      @include first-breakpoint
        width: 50%
      @include third-breakpoint
        width: 100%
        padding-left: $paddingBase*0.5
        padding-right: $paddingBase*0.5
      .form-element-container
        margin-bottom: $paddingBase*1.5
      &.submit, &.full-width
        width: 100%
      label
        display: block
        padding-left: 1px
        //margin-bottom: $paddingBaseRem*0.5
      input[type=text]::placeholder
        opacity: 1
        color: $vivaGreenDark
      input[type=text], select
        max-width: 100%
        @include third-breakpoint
          width: 100%
        border: $border
        color: $vivaGreenDark
        padding: $paddingForm
        background: $c_white
        border-radius: $baseBorderRadius
      .form-element.range-type
        input[type=text]
          width: 46%
          @include third-breakpoint
            width: 47%
          @include smallest-view
            width: 46%
          &:first-child
            margin-right: 1%
          &:last-child
            margin-left: 1%
        .form-slider
          display: flex
          @include third-breakpoint
            flex-wrap: wrap
          .input-container
            width: 15%
            @include third-breakpoint
              width: 100%
            position: relative
            &:after
              content: ',- Kč'
              color: $c_gray6
              font-weight: bold
              position: absolute
              right: $paddingBase
              top: 50%
              transform: translate(0, -50%)
            input[type=text]
              width: 100%
              padding-right: $paddingBase*5
              text-align: right
          .slider-container
            width: 70%
            padding: 0 $paddingBase*6
            @include third-breakpoint
              height: 34px
              width: 100%
              margin: $paddingBase 0
            @include smallest-view
              padding-left: $paddingBase*3
              padding-right: $paddingBase*3
            .slider-container__slider
              top: 8px
              .noUi-handle
                background: $c_green3
                border-color: $c_green3
                background-image: linear-gradient(to bottom,$c_green3 0,$c_green3 100%)
                box-shadow: none
                &:hover
                  cursor: pointer
              .noUi-connect
                background: $c_yellow1
                background-image: linear-gradient(to bottom, $c_yellow2 0,$c_yellow1 100%)
      .form-list-of-links
        display: block
        .form-list-of-links__list--selected
          background: $c_white
          border: $border
          border-radius: $baseBorderRadius
          padding: $paddingBase*0.5 $paddingBase
          color: $vivaGreenDark
          &:hover
            cursor: pointer
        .form-list-of-links__list--list
          border: $border
          border-radius: $baseBorderRadius
          a
            text-decoration: none
            padding: $paddingForm
            color: $c_black
            &:hover
              background: $c_gray10
              color: $c_black
      &.submit
        text-align: center
        padding-bottom: 0
        .form-element-container
          margin-bottom: 0
          position: relative
          height: 46px
        a
          @include buttonDefault()
          margin-left: $paddingBase
          right: calc(50% - 135px)
        button
          @include buttonDefaultForButtonEl()
          margin-right: $paddingBase
          left: calc(50% - 135px)
        button, a
          width: 120px
          position: absolute
          box-shadow: none
          background-color: $c_white
          color: $vivaGreenDark
          border: 1px solid $vivaGreenDark
          padding-top: $paddingBase*0.5
          padding-bottom: $paddingBase*0.5
          text-transform: uppercase
          &:hover
            background-color: $vivaGreenDark
            color: $c_white

  &.app-2
    form
      .joined-element-container
        .form-element.range-type
          .form-slider
            .input-container
              &:after
                content: ',- CZK'
              input[type=text]
                padding-right: $paddingBase*6
