.carousel
  .splide__pagination
    bottom: $paddingBase
    padding-right: $paddingBase*3
    right: 0
    left: auto
    width: 100%
    justify-content: flex-end
    transform: none
  .splide__pagination__page
    $size: 20px
    width: 20px
    height: 20px
    &.is-active
      transform: none
      background: $vivaYellow
      opacity: .9
  .carousel__item
    height: 315px
    @include third-breakpoint
      min-height: 315px
      height: auto
    > a
      width: 100%
      height: 100%
      position: relative
      display: block
      color: $c_gray6
      text-decoration: none
      img
        position: absolute
        z-index: -1
        max-width: none
        height: 100%
      .carousel__text-content
        position: absolute
        left: 0
        top: 0
        background: rgba(255,255,255,.95)
        max-width: 450px
        margin: $paddingBase*3
        padding: $paddingBase*1.5
        @include third-breakpoint
          position: relative
          margin-top: 0
          top: $paddingBase*1.5
          display: block
          max-width: 100%
        .carousel__text-content--headline
          display: block
          font-weight: bold
          font-size: 28px
          line-height: 34px
          margin-bottom: $paddingBase*0.5
        .carousel__text-content--text
          display: block
          font-size: $fontSizeBase
          line-height: $lineHeightBase
        .carousel__text-content--button
          @include buttonDefault()