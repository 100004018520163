.article-detail
  display: flex
  justify-content: space-between
  @include first-breakpoint
    display: block
  .article-detail__side
    order: 1
    width: 31%
    @include first-breakpoint
      width: 100%
      text-align: center
    .article-detail__side--date
      font-size: 13px
      line-height: 18px
      padding: $paddingBase 0
      font-family: $fontFamilyBase
      &:before
        @include addFasIcon($fa-var-calendar)
        font-size: 18px
        margin-right: $paddingBase*0.5
        position: relative
        top: -2px
  .article-detail__content
    order: 2
    width: 67%
    @include first-breakpoint
      width: 100%
    h1
      color: $vivaGreenDark
    ul, ol
      padding-left: $paddingBase*4
    p
      padding: $paddingBase*1.5 0