//-- base variables
$dirFonts: '/assets/fonts/'
$fontFamilyBase: 'barlow', sans-serif
$fontFamilyMontserrat: 'montserrat', sans-serif
$fontFamilyRajdhani: 'Rajdhani', sans-serif
$fontSizeBase: 16px
$lineHeightBase: 24px
$defaultWidth: 930px
$paddingBase: 10px
$paddingBaseRem: 16px
$baseWidth: 1110px
$maxModalWindowWidth: 950px

//-- breakpoints mixins
$fullViewBreakpoint: 1200px
$firstBreakpoint: 995px
$secondBreakpoint: 730px
$smallestBreakpoint: 550px

//-- colors
$c_white: #ffffff
$c_black: #000000
$c_gray1: #333333
$c_gray2: #737373
$c_gray3: #f6f6f6
$c_gray4: #464a4c
$c_gray5: #eeeeee
$c_gray6: #212529
$c_gray7: #e9e9e9
$c_gray8: #f4f4f4
$c_gray9: #343a40
$c_gray10: #e9ecef
$c_gray11: #b5b5b5
$c_gray12: #9d9d9d
$c_gray13: #212529
$c_gray14: #dee2e6
$c_gray15: #b7b7b7
$c_gray16: #dddddd
$c_gray17: #cccccc
$c_gray18: #363636
$c_gray19: #C3C3C3
$c_gray20: #F5F5F5
$c_gray21: #4A4A4A
$c_gray22: #5c5d62ff
$c_gray23: #e2e2e2
$c_green1: #73b735
$c_green2: #292b2c
$c_green3: #43ac34
$c_yellow1: #f9c231
$c_yellow2: #c78a0d
$c_yellow3: #E89630
$c_blue1: #2582c5
$c_red: #ff0000
$c_red02: #ed1c24
$c_red03: #a90d00
$c_red04: #ff4949
$c_lightGreen: #d8efd5
$vivaGreenDark: #3fae29
$vivaGreenLight: #43ac35
$vivaYellow: #fdc52a

//-- custom vars
$baseBorderRadius: $paddingBase*0.5
$headerDropDownBorder: 1px solid rgba(0,0,0,0.15)