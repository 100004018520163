.square-banners
  display: flex
  justify-content: space-between
  padding: $paddingBase*3 0
  @include second-breakpoint
    flex-wrap: wrap
    justify-content: center
  .square-banners__item
    @include second-breakpoint
      &:first-child
        margin-right: $paddingBase*2
    @include third-breakpoint
      &:first-child
        margin: 0
    .banners__item--image
      display: block
      width: 350px
      height: 245px
      overflow: hidden
      @include first-breakpoint
        width: 300px
        height: 205px
      @include second-breakpoint
        width: 350px
        height: 245px
        margin-bottom: $paddingBase*2
      @include third-breakpoint
        width: 100%
        height: auto

      img
        transition: 450ms
    .banners__item--text
      position: absolute
      left: 0
      top: 0
      z-index: 2
      width: 100%
      height: 100%
      text-align: center
      display: table
      background: rgba(0,0,0,.5)
      padding: $paddingBase*1.5
      font-size: 24px
      line-height: 32px
      color: $c_white
      opacity: 0
      transition: 500ms
      span
        display: table-cell
        vertical-align: middle
    a
      color: $c_white
      display: block
      text-decoration: none
      &.square-banners__item--content
        position: relative
        display: block
      &:hover
        text-decoration: none
    h2
      text-transform: uppercase
      line-height: 29px
      font-size: 24px
      background: $vivaGreenDark
      padding: $paddingBase $paddingBase*2.5
      position: relative
      &:after
        position: absolute
        z-index: 2
        top: 100%
        left: calc(100% - 90px)
        $size: 10px
        content: ''
        width: 0
        height: 0
        display: block
        border-left: $size solid transparent
        border-right: $size solid transparent
        border-top: $size solid $vivaGreenDark
    &:hover
      h2:after
        display: none
      a.square-banners__item--content
        .banners__item--image
          img
            transform: scale(1.1)
        .banners__item--text
          opacity: 1