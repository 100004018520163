.people-list
  display: flex
  flex-wrap: wrap
  .people-list__person
    width: 33%
    @include third-breakpoint
      width: 50%
    @include smallest-view
      width: 100%
    padding: $paddingBase*1.5 $paddingBase*1.5 $paddingBase*3 $paddingBase*1.5
    transition: 500ms
    &:hover
      box-shadow: 0 5px 5px 0 rgba(0,0,0,.1)
    a
      text-decoration: none
      &:hover
        text-decoration: underline
    i
      margin-right: $paddingBase*0.5
    .name
      font-weight: bold
      font-size: 24px
      line-height: 29px
      text-align: center
      padding-bottom: $paddingBase
    .position
      text-align: center
      font-weight: bold
      padding: $paddingBase*1.5 0
    .image
      text-align: center
      img
        transition: 500ms
        &:hover
          transform: scale(1.05)