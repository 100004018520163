@mixin menu-full-view
  @media (min-width: $firstBreakpoint+1)
    @content

@keyframes moveIn
  from
    top: -100px
  to
    top: 0

.move-fixed
  animation-name: moveIn
  animation-duration: 400ms
  animation-timing-function: linear

.menu
  @import 'menu-horizontal'
  @import 'menu-vertical'
  @include second-breakpoint
    display: none
  &.viva__top-menu
    .part-of-mobile-menu
      display: none
    &.viva_top-menu--fixed
      position: fixed
      left: 0
      top: 0
      z-index: 4
      background: $c_white
      width: 100%
    .viva__top-menu--container
      display: flex
      > ul
        flex-direction: row
        flex-grow: 1
        //CUSTOM: eshop show on top lvl but only for mobile version
        //&#menuGroup0
          > li:last-child
            display: none
            @include second-breakpoint
              display: block
        &.viva__search-container
          justify-content: flex-end
          flex-basis: 70px
          flex-grow: 0
          //padding-left: $paddingBase*2.5
          li
            flex-grow: 0
            > div
              position: relative
              display: table
              color: $c_green1
              background: $c_gray3
              height: 100%
              padding: 0 15px
              > span
                display: table-cell
                vertical-align: middle
                height: 100%
              &:hover
                cursor: pointer
                .search-form
                  display: block
    box-shadow: 0 5px 20px 0 #e0e0e0
    a
      text-decoration: none
      font-size: 15px
    ul
      > li
        flex-grow: 1
        > a
          display: table
          width: 100%
          padding: $paddingBase
          font-weight: bold
          text-transform: uppercase
          color: $c_gray1
          position: relative
          &:after
            content: ''
            width: 1px
            background: $c_gray2
            position: absolute
            top: 18px
            bottom: 18px
            right: 0
          img
            width: auto
            height: 48px
            transform: scale(.92)
            transition: .3s
            padding-right: $paddingBase
          span
            display: table-cell
            vertical-align: middle
          @include menu-full-view
            &:hover
              &:before
                @include addFasIcon($fa-var-caret-left)
                position: absolute
                right: 0
                top: 50%
                transform: translate(0, -50%)
                color: $c_gray2
                font-size: 20px
              img
                transform: scale(1)
        &:first-child
          a
            padding-left: 0
      li
        ul
          margin-top: -1px
          border-radius: $baseBorderRadius
          border: $headerDropDownBorder
          padding: 0 $paddingBase*1.5
          background: $c_white
          li
            padding: $paddingBase*0.5 $paddingBase
            a
              font-size: 17px
              font-weight: normal
              text-transform: none
              padding: $paddingBase*0.2 0
              &:after, &:before
                display: none
              &:hover
                //transform: scale(1.01)
                font-size: 18px
                transition: .3s
                &:before
                  display: none
            &:not(:last-child)
              border-bottom: 1px solid rgba(127, 188, 73, 0.5)
            ul
              border-left: none
              left: 99%
    @import 'menu-responsive'
.menu-reposive__toggle-button
  display: none
  @include second-breakpoint
    display: block
    border: 1px solid $vivaGreenDark
    background: transparent
    cursor: pointer
    color: $vivaGreenDark
    border-radius: $paddingBase*0.5
    padding: $paddingBase*0.5 $paddingBase
    background: $c_white
    position: absolute
    top: 50%
    right: 0
    transform: translate(0, -50%)
    svg
      $size: 24px
      width: $size
      height: $size
      position: relative
      top: 2px
      left: 1px
  @include third-breakpoint
    right: $paddingBase*2