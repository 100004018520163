.packing-paragraphs-container
  text-align: center
  .packing-paragraphs
    text-align: justify
    &.hide
      overflow: hidden
      height: 114px
  > span
    text-transform: uppercase
    text-align: center
    font-weight: bold
    display: inline-block
    cursor: pointer
    &:before
      @include addFasIcon($fa-var-caret-up)
      display: block
      color: $c_white
      background-color: $vivaGreenDark
      padding: 4px 10px
      border-radius: 50%
      font-size: 35px
      margin-bottom: $paddingBase*0.5
    &.hide
      &:before
        content: fa-content($fa-var-caret-down)