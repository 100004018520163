header
  text-align: left
  background-image: url('/assets/images/corner.jpg')
  background-repeat: no-repeat
  background-position: 100% calc(100% - 38px)
  @include second-breakpoint
    position: relative
  @include third-breakpoint
    background-image: none
  .inner
    position: relative
  .christmas-text
    position: absolute
    bottom: 5px
    left: 50%
    transform: translate(-45%, 0)
    img
      max-height: 46px
    @include first-breakpoint
      display: none
  .forklift-background
    background-image: url('/assets/images/vozik.png')
    //background-image: url('/assets/images/vozik_vanoce.png')
    background-repeat: no-repeat
    background-position: 100% 10px
    &.vanoce-1,
    &.vanoce-2,
    &.vanoce-3,
    &.vanoce-4,
    &.novy-rok-2025
      background-position: 100% 0
    &.vanoce-1
      background-image: url('/assets/images/vanoce/vanoce_1.jpg')
    &.vanoce-2
      background-image: url('/assets/images/vanoce/vanoce_2.jpg')
    &.vanoce-3
      background-image: url('/assets/images/vanoce/vanoce_3.jpg')
    &.vanoce-4
      background-image: url('/assets/images/vanoce/vanoce_4.jpg')
    &.novy-rok-2025
      background-image: url('/assets/images/vanoce/novy-rok.jpg')
    @include third-breakpoint
      background-position: 80% 10px
      &.vanoce-1,
      &.vanoce-2,
      &.vanoce-3,
      &.vanoce-4,
      &.novy-rok-2025
        background-position: 80% 0
      //background-image: url('/assets/images/vozik_vanoce_h80.png')
      //background-image: none
    @include smallest-view
      background-image: none
      &.vanoce-1,
      &.vanoce-2,
      &.vanoce-3,
      &.vanoce-4,
      &.novy-rok-2025
        background-image: none
  .slogan-and-phone-number
    $this: &

    font-family: $fontFamilyRajdhani
    font-size: 32px
    line-height: 40px
    position: absolute
    left: 0
    bottom: 13px
    width: 100%
    text-align: center
    //display: none
    .slogan-and-phone-number__flag-container
      display: none

    .slogan-and-phone-number__container
      text-align: left
      display: inline-block

      strong
        font-weight: 500

      &.multiple-numbers
        text-align: center
        .slogan-and-phone-number__number
          font-size: 27px

    @include responsive-breakpoint-min(1800px)
      &.viva_top-slogan-phone--fixed
        display: block
        position: fixed
        z-index: 5
        left: 50%
        top: 4px
        width: auto
        transform: translate(600px, 0px)
        font-size: 25px
        line-height: 30px

        .slogan-and-phone-number__flag-container
          display: inline-block
          width: 120px
          font-size: 16px

        .slogan-and-phone-number__container
          &.multiple-numbers
            .slogan-and-phone-number__number
              font-size: 20px

              strong
                font-weight: 500

    @include second-breakpoint
      display: none
  .logo
    position: relative
    display: inline-block
    z-index: 2
    top: 18px
    @include third-breakpoint
      img
        max-width: 210px
    @include responsive-breakpoint-min(1800px)
      &.viva_top-logo--fixed
        position: fixed
        z-index: 5
        right: 50%
        top: 5px
        transform: translate(-600px, 0px)
        height: 70px
        img
          max-height: 100%
  .headline-line
    width: 100%
    &.yellow
      height: 16px
      background: $vivaYellow
    &.green
      height: 24px
      background: $vivaGreenDark