&.vertical
  ul
    list-style: none
    > li
      a
        padding: $paddingBase
    li
      width: 200px
      position: relative
      white-space: nowrap
      a
        display: block
      ul
        background: #fff
        display: none
        position: absolute
        z-index: 3
        left: 100%
        top: 0
      &:hover > ul
        display: inline-block