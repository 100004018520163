.footer-person-small-form
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  align-items: flex-end
  border-top: 1px solid $c_lightGreen
  padding-top: $paddingBase*3
  margin-top: $paddingBase*3
  @include third-breakpoint
    flex-wrap: wrap
  > div
    width: 49%
    &.footer-person-small-form__form-button
      text-align: right
      @include first-breakpoint
        text-align: center
      font-size: 17px
      line-height: 25px
      padding: $paddingBase*3 0
      img
        vertical-align: middle
        margin-right: $paddingBase*1.5
        @include first-breakpoint
          margin-bottom: $paddingBase*2
      button
        @include buttonDefaultForButtonEl()
        font-weight: bold
        font-size: 17px
        line-height: 25px
        margin-top: 0
        padding: $paddingBase $paddingBase*2
        text-transform: uppercase
    &.footer-person-small-form__form
      width: 42%
    &.footer-person-small-form__full-view
      width: 100%
      &.footer-person-small-form__form-button
        text-align: center
    @include third-breakpoint
      width: 100%
      &.footer-person-small-form__form
        width: 100%
      &:first-child
        width: 100%
        margin-bottom: $paddingBase*3
  .footer-person-small-form__form
    .message
      color: $c_white
      margin-bottom: $paddingBase
      background: $vivaGreenDark
      padding: $paddingBase
      &:before
        position: relative
        top: -1px
        padding: 0 $paddingBase 0 $paddingBase*0.5
      &.success
        &:before
          @include addFasIcon($fa-var-check)
      &.error
        background: $c_red
        &:before
          @include addFasIcon($fa-var-exclamation)
    form
      display: flex
      flex-wrap: wrap
      justify-content: space-between
      > div
        &:first-child
          padding-right: $paddingBase*1.5
          > div:first-child
            padding-bottom: $paddingBase*1.5
        padding-bottom: $paddingBase*1.5
        width: 50%
        @include smallest-view
          width: 100%
          &:first-child
            padding-right: 0
        &:last-child
          padding-bottom: 0
        &.full-width
          width: 100%
      input[type=text],
      textarea
        @include defaultFormElementStyle()
      input[type=text]
        width: 100%
      button[type=submit]
        @include buttonDefaultForButtonEl()
        width: 100%
      textarea
        width: 100%
        height: 100%
      div.error
        input[type=text],textarea
          border-color: $c_red
