.cookie-bar
  display: none
  position: fixed
  bottom: 0
  left: 0
  width: 100%
  background-color: $c_gray18
  color: $c_white
  z-index: 11
  text-align: center
  font-size: 14px
  padding: $paddingBase*0.5 $paddingBase
  button
    margin-left: $paddingBase*5
    background: $c_green3
    color: $c_white
    border: none
    padding: $paddingBase*0.5 $paddingBase
    transition: 500ms
    text-align: center
    &:hover
      cursor: pointer
      background-color: $vivaYellow
      color: $c_black
    @include third-breakpoint
      display: block
      width: 100%
      margin: $paddingBase*2 0 0 0
  a
    color: $c_yellow3
    text-decoration: underline
    &:hover
      text-decoration: none