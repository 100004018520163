.forklift-compare-empty
  text-align: center
  padding: 20px 0

.forklift-compare-toolbar
  display: flex
  justify-content: space-between
  padding: $paddingBase*3 0 $paddingBase 0

.forklift-compare-button
  @include buttonDefaultForButtonEl()
  margin-top: 0
  font-size: 14px
  line-height: 16px
  &:hover
    color: $c_white

.forklift-compare
  display: flex
  padding: $paddingBase*3 0

  &--header
    min-width: 230px
    max-width: 230px
    width: 230px
    flex-basis: auto
    flex-grow: 1
    @include smallest-view
      min-width: 190px
      max-width: 190px
      width: 190px

  &--list
    overflow-x: auto
    &--list
      display: flex

.forklift-compare-header-items
  .forklift-compare-header-items--item
    font-weight: bold
    border-left: 1px solid $c_gray23

    &.name
      height: 81px

    &.image
      border-left: none

    span
      font-size: 13px

.forklift-compare-header-items .forklift-compare-header-items--item,
.forklift-compare-item .forklift-compare-item--attr
  border-bottom: 1px solid $c_gray23
  padding: 10px 15px
  border-right: 1px solid $c_gray23
  height: 45px
  &:nth-child(even)
    background: $c_gray8

  &.button
    display: flex
    align-items: center
    justify-content: center

  &.image
    height: 220px
    overflow: hidden
    display: flex
    align-items: flex-end

  &.name
    height: 80px
    overflow: hidden
    display: flex
    align-items: center

  &.hidden
    background: transparent
    border-left: none
    &.image
      border-bottom: none

.forklift-compare-header-items
  .forklift-compare-header-items--item
    &.name
      height: 81px

.forklift-compare-item
  min-width: 280px
  max-width: 280px
  width: 280px
  flex-basis: auto
  flex-grow: 1
  position: relative
  border-top: 1px solid $c_gray23

  &--remove
    position: absolute
    top: 10px
    right: 10px
    background: rgba(255, 255, 255, 0.7)
    border-radius: 100%
    width: 20px
    height: 20px
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer

  .name
    font-weight: bold

  .forklift-compare-item--attr
    &.hilight
      background: $c_gray12

.catalog-compare-icon

  width: 30px

  &.bazar-detail
    width: auto
    @include compareIconGray()

    svg
      width: 43px

  &.bazar-list
    cursor: pointer
    display: inline-block
    position: absolute
    bottom: 65px
    right: 15px
    border: 1px solid $c_gray21
    border-radius: 5px
    @include compareIconGray()

    svg
      display: block
      width: 25px
      height: auto
      margin: 0 auto
      path
        stroke: transparent !important

    //img
      display: block
      width: 25px

  &.in-compare
    //background: $c_yellow1
    border-radius: 10px
    @include compareIconGrayFullFill()


    &.bazar-list
      background: $c_gray21
      border-radius: 5px
      svg
        path
          stroke: transparent !important


.breadcrumb-catalog-compare
  position: relative

  .forklift-compare-entrance-button
    position: absolute
    right: 0
    top: 50%
    width: 50px
    transform: translateY(-50%)
    line-height: normal
    border: 1px solid $c_gray21
    border-radius: 5px
    height: 100%
    display: flex
    align-content: center
    justify-content: center
    background: $c_gray10

    .forklift-compare-entrance-button--count
      display: none
      position: absolute
      bottom: 4px
      right: 5px
      color: $c_white
      font-weight: bold
      font-size: 13px


    @include responsive-breakpoint-min($secondBreakpoint)
      right: 105px

    @include compareIconGray()
    svg
      width: 35px
      //margin: 0 auto
      display: block

      path
        stroke: transparent !important

    &.in-compare
      background: $c_gray21

      @include compareIconGrayFullFill()
      svg
        path
          stroke: transparent !important

      .forklift-compare-entrance-button--count
        display: block
