.right-fixed-buttons
  position: fixed
  top: 40%
  right: 0
  z-index: 10
  @include second-breakpoint
    display: none
  a
    display: block
    position: absolute
    width: 335px
    margin-bottom: 2px
    border-radius: 5px 0 0 5px
    transition: 500ms
    right: -290px
    padding: $paddingBase $paddingBase $paddingBase $paddingBase*5
    color: $c_white
    text-decoration: none
    @include first-breakpoint
      padding: $paddingBase*0.5 $paddingBase*0.5 $paddingBase*0.5 $paddingBase*4.5
      right: -295px
    svg
      margin-right: $paddingBase*1.5
      width: 28px
      height: 24px
      position: absolute
      left: $paddingBase
      @include first-breakpoint
        width: 18px
        height: 16px
        top: 9px
    &:nth-child(2)
      top: 50px
    &:nth-child(3)
      top: 100px
    &:nth-child(4)
      top: 150px
    &:nth-child(5)
      top: 200px
    &:nth-child(6)
      top: 250px
    &.gray
      background: #333
      svg
        fill: $vivaGreenDark
    &.purpure
      background: #A349A4
    &.red
      background: $c_red
      &.youtube
        svg
          width: auto
          height: 32px
          top: 6px
          left: 7px
    &.green
      background: $vivaGreenDark
    &.blue
      background: $c_blue1
    &.yellow
      background: $vivaYellow
    &:hover
      text-decoration: none
      color: $c_white
      right: 0