/*!
 * splite 2.4.10
 */
@import "core/foundation/animations";
@import "core/foundation/variables";
@import "core/object/objects/container";
@import "core/object/objects/list";
@import "core/object/objects/pagination";
@import "core/object/objects/root";
@import "core/object/objects/slide";
@import "core/object/objects/slider";
@import "core/object/objects/spinner";
@import "core/object/objects/track";
@import "core/object/modifiers/draggable";
@import "core/object/modifiers/fade";
@import "core/object/modifiers/rtl";
@import "core/object/modifiers/ttb";

@import "default/foundation/variables";
@import "default/object/objects/arrow";
@import "default/object/objects/pagination";
@import "default/object/objects/progress";
@import "default/object/modifiers/nav";
@import "default/object/modifiers/rtl";
@import "default/object/modifiers/ttb";