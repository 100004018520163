body.modal-is-open
  overflow: hidden
.modal-window
  display: none
  position: fixed
  z-index: 1000
  left: 0
  right: 0
  top: 0
  bottom: 0
  background: rgba(0, 0, 0, 0.5)
  overflow-x: hidden
  overflow-y: auto
  .modal-window__container
    position: relative
    width: auto
    max-width: $maxModalWindowWidth
    margin: $paddingBase*2 auto
    .modal-window__container-content
      position: relative
      .confirm-and-close-text
        background: $c_white
        text-align: center
        padding: $paddingBase*2
      .inner-loading
        background: rgba(255, 255, 255, 0.6)
        position: absolute
        left: 0
        top: 0
        width: 100%
        height: 100%
        > div
          background: transparent
          position: absolute
          top: 50%
          left: 0
          width: 100%
          transform: translate(0, -50%)
          text-align: center
    .modal-window__container-headline
      position: relative
      background: $vivaGreenDark
      color: $c_white
      padding: $paddingBase*1.5 $paddingBase*4 $paddingBase*1.5 $paddingBase*2
      display: none
      .modal-window__container-close
        position: absolute
        right: $paddingBase*2
        top: 50%
        transform: translate(0, -50%)
        cursor: pointer
        display: block
        &:hover
          color: $vivaYellow
  //&.viva__modal-window
    .modal-window__container
      border-radius: 5px