@mixin menuSitebarActive()
  background: $vivaGreenDark
  color: $c_white
  font-weight: bold
  &:hover
    background: $vivaGreenDark

.menu-sidebar
  padding-bottom: $paddingBase*4.5
  ul
    list-style: none
    li
      a
        border-bottom: 1px solid rgba(127, 188, 73, 0.5)
        color: $c_green2
        background: $c_lightGreen
        display: block
        padding: $paddingBase*0.5 $paddingBase*1.5 $paddingBase*0.5 $paddingBase*1.5
        transition: 300ms
        text-decoration: none
        &:hover
          background: rgba(216, 239, 213, .5)
          text-decoration: underline
        &.open
          @include menuSitebarActive()
      ul
        li
          ul
            li
              a
                padding-left: $paddingBase*4
              ul
                li
                  a
                    padding-left: $paddingBase*6
                  ul
                    li
                      a
                        padding-left: $paddingBase*8
  > ul
    > li
      > ul
        > li
          &:last-child
            > a
              border-bottom-left-radius: $baseBorderRadius
              border-bottom-right-radius: $baseBorderRadius
      > a
        @include menuSitebarActive()
        padding: $paddingBase $paddingBase*4
        font-size: 19px
        line-height: 29px
        border-top-left-radius: $baseBorderRadius
        border-top-right-radius: $baseBorderRadius