.form-builder-ajax-form
  background: $c_white
  display: flex
  flex-wrap: wrap
  padding: $paddingBase
  .error-form-message
    width: 100%
    color: $c_white
    background: $c_red
    text-align: center
    font-weight: bold
    padding: $paddingBase 0
  .form-builder-ajax-form__item
    padding: $paddingBase
    width: 50%
    @include third-breakpoint
      width: 100%
    &.full-width
      width: 100%
    label
      display: block
    input[type=text], textarea, select
      width: 100%
      @include defaultFormElementStyle()
    button[type=submit]
      @include buttonDefaultForButtonEl()
    &.error-container
      input[type=text], textarea, select
        border-color: $c_red
      .error-message
        color: $c_red