.table-responsive-container
  width: 100%
  overflow-x: auto
table.catalog-table-list-of-items
  margin-top: $paddingBase*4
  border: 1px solid $c_gray14
  width: 100%
  @include third-breakpoint
    width: $baseWidth
  tr
    td
      padding: $paddingBaseRem*0.5
      border: 1px solid $c_gray14
      vertical-align: middle
      a
        text-decoration: none
        &:hover
          text-decoration: underline
        &.catalog-table-list-of-items__button-detail
          display: none
          margin-top: $paddingBase*2
          background: $vivaGreenDark
          color: $c_white
          text-align: center
          box-shadow: 0 5px 7px 0 rgba(160, 160, 160, 0.5)
          font-family: $fontFamilyMontserrat
          font-weight: bold
          text-transform: uppercase
          transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
          padding: $paddingBaseRem*0.5 0
          &:hover
            background: $vivaYellow
      &:first-child
        width: 345px
        div
          display: flex
          align-items: center
          a
            img
              display: block
              &.bazar-no-image-big
                display: none
            &.big-image-on-hover
              position: relative
              span
                display: none
                position: absolute
                z-index: 3
                left: 50%
                top: 0
                padding: $paddingBase*0.5
                background: $c_white
                border: 2px solid $vivaGreenDark
                img
                  display: block
                  max-width: none
              &:hover
                span
                  display: block
            &:last-child
              padding-left: $paddingBase
      &:last-child
        width: 115px
    &:nth-child(odd)
      td
        background: rgba(0,0,0,0.05)
    &:hover
      td
        background: rgba(0,0,0,0.075)
    //@include third-breakpoint
      td
        display: block
        border: none
        a
          &.catalog-table-list-of-items__button-detail
            display: block
        &.have-parameter-name
          span:before
            content: attr(data-parameter-name)
            font-weight: bold
        &.is-empty
          display: none
        &,
        &:first-child,
        &:last-child
          width: 100%
        &:last-child
          border-bottom: 1px solid $c_gray14
        &:first-child
          div
            display: block
            a
              img
                margin: 0 auto
                &.bazar-no-image-small
                  display: none
                &.bazar-no-image-big
                  display: block
              display: block
              &:last-child
                padding-top: $paddingBase
                padding-left: 0
                font-size: 20px
                font-weight: bold
              &.big-image-on-hover
                > img
                  display: none
                span
                  display: block
                  position: static
                  border: none
                  background: transparent
                  padding: 0
                  img
                    max-width: 100%
  thead
    tr
      td
        a
          float: right
          margin-left: $paddingBase*0.2
          i
            color: $c_gray15
            &:hover
              color: $vivaGreenDark
          &.active
            i
              color: $vivaYellow
      //@include third-breakpoint
        display: none
      td
        font-weight: bold
        border-bottom-width: 2px
      &,&:nth-child(odd)
        td
          background: transparent