.product-list-header
  display: flex
  flex-wrap: wrap
  padding-bottom: $paddingBase*3
  > div
    width: 50%
    text-align: right
    @include smallest-view
      width: 100%
      text-align: left
      &:first-child
        padding-bottom: $paddingBase*2
    &:first-child
      text-align: left
      font-size: 19px
      line-height: 29px
.product-list-form-ask-for-demand
  text-align: right
  font-size: 22px
  line-height: 33px
  padding: $paddingBase*3 0
  a
    text-decoration: none
    transition: 300ms
    &:hover
      text-decoration: underline
      color: $vivaYellow
  img
    vertical-align: middle
    margin-right: $paddingBase*1.5
  button
    @include buttonDefaultForButtonEl()
    font-weight: bold
    font-size: 20px
    line-height: 30px
    margin-top: 0
    padding: $paddingBase $paddingBase*2
    text-transform: uppercase