.homepage-content
  h1
    text-align: center
    font-size: 40px
    line-height: 48px
    padding: $paddingBase*3 0
  .homepage-content__text
    padding: $paddingBase*3 0
    text-align: center
    font-weight: bold
    *
      font-size: 48px
      line-height: 72px
    img
      margin-right: $paddingBase*4
      vertical-align: middle
  .list-of-annotations
    .list-of-annotations__item
      h3
        min-height: 77px