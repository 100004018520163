@mixin listOfChildrenAnnotationSwitch()
  .list-of-children-annotations__item--image
    order: 2
    text-align: right
    padding-right: 0
    padding-left: $paddingBase*3
  .list-of-children-annotations__item--content
    order: 1

@mixin listOfChildrenAnnotationMobile()
  .list-of-children-annotations__item--image
    order: 2
    text-align: center
    .image
      padding-top: $paddingBase*2
      text-align: center
  .list-of-children-annotations__item--content
    order: 1

@mixin listOfChildrenAnnotationMobile2()
  .list-of-children-annotations__item--image,
  .list-of-children-annotations__item--content
    width: 100%
  .list-of-children-annotations__item--image
    text-align: center

.list-of-children-annotations__item
  $borderStyle: 1px solid $c_lightGreen
  padding: $paddingBase*4 $paddingBase*1.5
  display: flex
  flex-wrap: wrap
  border-top: $borderStyle
  &:first-of-type
    border-top: none
  //&:first-child
    border-top: $borderStyle
  .list-of-children-annotations__item--image
    width: 30%
    padding-right: $paddingBase*3
    img
      max-width: 100%
    a
      img
        transition: 500ms
      &:hover
        img
          transform: scale(.95)
  .list-of-children-annotations__item--content
    width: 70%
    text-align: justify
    h2
      text-align: left
      padding-bottom: $paddingBase
      a
        text-decoration: none
  &.switch
    @include listOfChildrenAnnotationSwitch()
  @include third-breakpoint()
    @include listOfChildrenAnnotationMobile()
    @include listOfChildrenAnnotationMobile2()
    &.switch
      @include listOfChildrenAnnotationMobile()
      @include listOfChildrenAnnotationMobile2()

.list-of-children-annotations
  padding: $paddingBase*2 0

  .list-of-children-annotations__item.item-type-361
    .list-of-children-annotations__item--image
      width: 20%
      img
        width: auto
        height: 100px

  &.list-of-children-annotations__start-right
    .list-of-children-annotations__item
      padding-left: 0
      padding-right: 0
      &:nth-child(2n+1)
        @include listOfChildrenAnnotationSwitch()
  &.list-of-children-annotations__start-left
    .list-of-children-annotations__item
      padding-left: 0
      padding-right: 0
      &:nth-child(2n)
        @include listOfChildrenAnnotationSwitch()
  @include third-breakpoint()
    &.list-of-children-annotations__start-left,
    &.list-of-children-annotations__start-right
      &:nth-child(2n+1), &:nth-child(2n)
        @include listOfChildrenAnnotationMobile()
      @include listOfChildrenAnnotationMobile2()
  &.list-of-children-annotations__border-top
    .list-of-children-annotations__item
      &:first-of-type
        border-top: 1px solid $c_lightGreen