// https://dev.to/hankchizljaw/a-modern-css-reset-6p3
// https://necolas.github.io/normalize.css/8.0.1/normalize.css

*,
*::before,
*::after
  box-sizing: border-box

body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd
  margin: 0

ul,
ol
  padding: 0

img
  max-width: 100%
  border-style: none

input,
button,
textarea,
select
  font: inherit

a
  background-color: transparent

sub,
sup
  font-size: 75%
  line-height: 0
  position: relative
  vertical-align: baseline

sub
  bottom: -0.25em

sup
  top: -0.5em

textarea
  overflow: auto

[type="checkbox"],
[type="radio"]
  box-sizing: border-box
  padding: 0

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section
  display: block

blockquote, q
  quotes: none

blockquote:before, blockquote:after,
q:before, q:after
  content: ''
  content: none

table
  border-collapse: collapse
  border-spacing: 0