.form-element
  .drop-down
    position: relative
    .drop-down__items
      display: none
      position: absolute
      left: 0
      top: 100%
      background: $c_white
      border: 1px solid rgba(63,174,41,.5)
      border-radius: $baseBorderRadius
      width: 100%
      z-index: 3
      label
        margin: 0 !important
        padding: $paddingBase*0.5 $paddingBase
        input[type=checkbox]
          margin-right: $paddingBase
        &:hover
          background: $c_gray8
          cursor: pointer
    .drop-down__selected
      border: 1px solid rgba(63,174,41,.5)
      background: $c_white
      color: $vivaGreenDark
      border-radius: $baseBorderRadius
      display: block
      padding: $paddingBase*0.5 $paddingBase
      position: relative
      cursor: pointer
      &:after
        @include addFasIcon($fa-var-chevron-down)
        position: absolute
        right: $paddingBase*1.5
        top: 50%
        transform: translate(0, -50%)
        font-size: 12px

@include device-mobile
  .form-element
    .drop-down
      .drop-down__items
        &.open-by-click
          display: block
          position: static
      .drop-down__selected
        &.open-by-click:after
          @include addFasIcon($fa-var-chevron-up)

@include device-desktop
  .form-element
    .drop-down
      &:hover
        .drop-down__items
          display: block
        .drop-down__selected
          &:after
            @include addFasIcon($fa-var-chevron-up)

.form-list-of-links
  display: inline-block
  position: relative
  min-height: $fontSizeBase
  .form-list-of-links__list
    position: relative
    .form-list-of-links__list--selected
      position: relative
      &:after
        @include addFasIcon($fa-var-chevron-down)
        position: absolute
        right: $paddingBase*1.5
        top: 50%
        transform: translate(0, -50%)
        font-size: 12px
    .form-list-of-links__list--list
      display: none
      background: $c_white
      a
        display: block

@include device-mobile
  .form-list-of-links
    .form-list-of-links__list
      .form-list-of-links__list--selected
        &.open-by-click:after
          @include addFasIcon($fa-var-chevron-up)
      .form-list-of-links__list--list.open-by-click
        display: block
        position: static

@include device-desktop
  .form-list-of-links
    .form-list-of-links__list
      &:hover
        .form-list-of-links__list--selected
          &:after
            @include addFasIcon($fa-var-chevron-up)
        .form-list-of-links__list--list
          position: absolute
          width: 100%
          z-index: 3
          top: 100%
          left: 0
          display: block