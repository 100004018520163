.search-form
  display: none
  position: absolute
  z-index: 3
  top: 100%
  right: 0
  border-radius: $baseBorderRadius
  padding: $paddingBase*1.5
  border: $headerDropDownBorder
  background: $c_white
  form
    border: 1px solid rgba(0,0,0,.15)
    border-radius: $baseBorderRadius
    padding-left: 2px
    input
      border: none
      padding: $paddingBase
    button
      border: none
      background: transparent
      transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
      padding: $paddingBase $paddingBase*1.5
      &:hover
        cursor: pointer
        background: $c_gray5
  @include second-breakpoint
    display: block
    position: static
    form
      input
        width: calc(100% - 60px)