@include second-breakpoint
  $borderBottomStyle: 1px solid rgba(127, 188, 73, 0.5)
  &.open-mobile-menu
    display: block
    padding-bottom: 0
    position: relative
    z-index: 11
  padding-bottom: $paddingBase*2
  &.viva_top-menu--fixed
    position: static
  .viva__top-menu--container
    display: block
    &.content-width
      padding: 0
    > ul
      display: block
      li
        border-bottom: $borderBottomStyle
        background: #f9f9f9
        &:last-child
          border-bottom: none
        &.menu-parent-show
          border-bottom: none
      &.viva__search-container
        padding-left: 0
        li
          border-bottom: none
          display: block
          > div
            background: transparent
            width: 100%
            padding: 0
            > span
              display: none
            .search-form
              display: block
              border-radius: 0
      > li
        > a
          text-align: center
  ul
    > li
      flex-grow: 1
      > a
        &:after
          display: none
        img
          display: none
    li
      a.menu-have-sub-items
        position: relative
        &:before
          position: absolute
          @include addFasIcon($fa-var-angle-right)
          margin-right: $paddingBase*1.5
          top: 50%
          transform: translate(0, -50%)
          right: 0
      display: none
      white-space: normal
      &.mobile-menu-back
        font-size: 17px
        font-weight: normal
        text-transform: none
        padding: 8px $paddingBase
        border-bottom: none
        background: $c_gray4
        color: $c_white
        &:hover
          cursor: pointer
        &:before
          @include addFasIcon($fa-var-angle-left)
          position: relative
          top: -1px
          margin-right: $paddingBase*1.5
      &.menu-show
        display: block
      &.menu-parent-show
        padding-top: 0
      &.open-parent, &.menu-parent-show
        display: block
        > a
          display: none
      ul
        border: none
        display: none
        position: static
        border-radius: 0
        padding: 0
        &.menu-show, &.menu-parent-show
          display: block
          margin-top: 0
        &.drop-down-is-open
          display: block
        li
          padding-left: 0
          padding-right: 0
          &:last-child
            border-bottom: none
          a
            padding-left: $paddingBase !important
            padding-right: $paddingBase !important
            display: block
          ul
            margin-top: $paddingBase
            border-left: none
            border-right: none
            border-color: $borderBottomStyle
            border-left: none
            border-right: none
            border-bottom: none
            li:last-child
              padding-bottom: 0