.list-of-annotations
  > div
    display: flex
    flex-wrap: wrap
    .list-of-annotations__item
      &:nth-child(1)
        background: $c_gray8
      &:nth-child(2)
        background: $c_gray7
    &:nth-child(odd)
      .list-of-annotations__item
        &:nth-child(1)
          background: $c_gray7
        &:nth-child(2)
          background: $c_gray8
  .list-of-annotations__item
    width: 50%
    @include second-breakpoint
      width: 100%
    padding: $paddingBase*3
    .list-of-annotations__item--content
      .annotation
        @include baseFontSizeLineHeight()
      display: flex
      @include third-breakpoint
          flex-wrap: wrap
      > div
        width: 50%
        @include third-breakpoint
          width: 100%
        &.image-date
          padding-right: $paddingBase*1.5
      .image
        img
          border: 1px solid $c_gray11
          display: block
          @include third-breakpoint
            margin: 0 auto
        a
          img
            transition: 500ms
          &:hover
            img
              transform: scale(.95)
      .date
        font-size: 13px
        line-height: 18px
        padding: $paddingBase 0
        &:before
          @include addFasIcon($fa-var-calendar)
          font-size: 18px
          margin-right: $paddingBase*0.5
          position: relative
          top: -2px
    .list-of-annotations__item--extralink
      text-align: right
      a
        @include buttonDefault()
    h3
      padding-bottom: $paddingBase*1.5
      a
        text-decoration: none
        &:hover
          text-decoration: underline