a.mobile-call-us-button-wa
  position: fixed
  right: 275px
  z-index: 10
  bottom: 80px
  background: #333
  display: none
  justify-content: center
  align-items: center
  width: 45px
  height: 45px
  border-radius: 5px
  @include second-breakpoint()
    display: flex
  svg
    fill: $vivaGreenDark
    width: 60%
    height: 60%

a.mobile-call-us-button
  width: 260px
  height: 66px
  color: $c_white
  position: fixed
  right: 15px
  z-index: 10
  font-size: 24px
  line-height: 36px
  bottom: 70px
  padding-left: 8px
  display: none
  @include second-breakpoint()
    display: block
  .mobile-call-us-button-container
    position: relative
    display: block
    span
      position: absolute
      z-index: 2
      left: 13px
      bottom: 13px