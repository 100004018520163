@mixin zoomIcon()
  position: relative
  &:after
    position: absolute
    z-index: 3
    @include addFasIcon($fa-var-search)
    color: $c_white
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    font-size: 32px
  &:before
    position: absolute
    z-index: 2
    left: 0
    top: 0
    width: 100%
    height: 100%
    background: rgba(0,0,0,.5)
    content: ''

@mixin baseFontSizeLineHeight()
  font-size: $fontSizeBase
  line-height: $lineHeightBase

@mixin addFasIcon($icon)
  @include fa-icon
  @include fa-base-definition
  //@extend %fa-icon
  //@extend .fas
  font-family: 'Font Awesome 5 Free'
  content: fa-content($icon)
  font-weight: 900

@mixin addFarIcon($icon)
  @include fa-icon
  @include fa-base-definition
  //@extend %fa-icon
  //@extend .far
  font-family: 'Font Awesome 5 Free'
  content: fa-content($icon)

@mixin buttonDefault()
  display: inline-block
  text-align: center
  background: $vivaGreenDark
  font-family: $fontFamilyMontserrat
  font-size: $fontSizeBase
  line-height: $lineHeightBase
  color: $c_white
  box-shadow: 0 5px 7px 0 rgba(160, 160, 160, 0.5)
  padding: $paddingBaseRem*0.5 $paddingBase
  margin-top: $paddingBase
  border-radius: $baseBorderRadius
  text-decoration: none
  &:hover
    background: $vivaYellow
    text-decoration: none
    transition: 300ms

@mixin buttonDefaultForButtonEl()
  @include buttonDefault()
  border: none
  cursor: pointer

@mixin defaultFormElementStyle()
  border: 1px solid rgba(63, 174, 41, 0.5)
  border-radius: $baseBorderRadius
  padding: $paddingBase*0.5 $paddingBase

@mixin compareIconGray()
  svg
    fill: $c_gray21 !important
    path
      stroke: $c_gray21 !important

@mixin compareIconGrayFullFill()
  svg
    fill: $c_white !important
    path
      stroke: $c_white !important
      &.icon-compare-background
        fill: $c_gray21

@mixin compareIconGreen()
  svg
    fill: $vivaGreenDark !important
    path
      stroke: $vivaGreenDark !important

@mixin compareIconGreenFullFill()
  svg
    fill: $c_white !important
    path
      stroke: $c_white !important
      &.icon-compare-background
        fill: $vivaGreenDark