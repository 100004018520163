&.horizontal
  ul
    display: flex
    list-style: none
    > li
      a
        padding: $paddingBase
    li
      position: relative
      white-space: nowrap
      a
        display: block
      ul
        background: #fff
        display: none
        position: absolute
        z-index: 3
        li
          ul
            top: 0
            left: 100%
      @include menu-full-view
        &:hover > ul
          display: block
