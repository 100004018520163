.gallery-list
  display: flex
  .gallery-item
    padding: $paddingBase
    &:first-of-type
      padding-left: 0
.gallery-list
  padding: $paddingBase*3 0 $paddingBase 0
  flex-wrap: wrap
  .gallery-item
    &.hide
      display: none
    a
      display: block
    width: 32%

    padding: 0
    margin-right: 2%
    margin-bottom: $paddingBase*2
    &:nth-child(3n)
      margin-right: 0
    @include second-breakpoint
      width: 49%
      &:nth-child(3n)
        margin-right: 2%
      &:nth-child(2n)
        margin-right: 0
    @include smallest-view
      width: 100%
      margin-right: 0
      &:nth-child(3n)
        margin-right: 0
    span
      display: block
      img
        display: block
      &:hover
        @include zoomIcon()
    &.youtube
      #bazar-youtube
        display: block
        width: 100%
        //height: 100%
        margin: 0 auto
        max-width: 100%
        //background: #000
        iframe
          width: 100% !important
          height: 100%
          min-height: 270px
      .film-container
        position: relative
        width: 100%
        //height: 100%
        //padding-top: 35px
        //padding-bottom: 35px
        box-sizing: border-box
        //background: url("/assets/images/background-film.svg") repeat-x 0 0
        //background-size: 100% auto
        //img.bg-film-image
          width: 100%
          position: absolute
          bottom: 0
          left: 0
.gallery-list__show-hide-container
  text-align: center
  .gallery-list__show-hide-button
    display: inline-block
    text-transform: uppercase
    text-align: center
    font-weight: bold
    &:hover
      cursor: pointer
    &:before
      @include addFasIcon($fa-var-caret-down)
      display: block
      color: $c_white
      background-color: $vivaGreenDark
      padding: 4px 10px
      border-radius: 50%
      font-size: 35px
      margin-bottom: $paddingBase*0.5
    &.show:before
      content: fa-content($fa-var-caret-up)
