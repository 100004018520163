.float-container
  display: none
  position: fixed
  right: $paddingBase*1.5
  bottom: $paddingBase*5
  z-index: 10
  justify-content: space-between

.button-go-up
  display: flex
  color: $c_white
  background-color: $vivaGreenDark
  border-radius: 50%
  width: 36px
  height: 36px
  padding: 0
  border: none
  transition: 500ms
  box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.5)
  justify-content: center
  align-items: center
  svg
    width: 10px
    height: 16px
  &:hover
    background-color: $vivaYellow
    transform: scale(1.1)
    cursor: pointer

.forklift-compare-entrance-button-float
  position: relative
  padding: 0
  width: 42px
  border: 1px solid $vivaGreenDark
  background: $c_white
  border-radius: 5px
  margin-right: 10px

  @include compareIconGreen()

  svg
    width: 35px
    display: block
    margin: 0 auto
    path
      stroke: $c_white !important


  .forklift-compare-entrance-button--count
    display: none
    position: absolute
    bottom: 0
    right: 3px
    color: $c_white
    font-weight: bold
    font-size: 13px

  &.in-compare
    background: $vivaGreenDark
    @include compareIconGreenFullFill()

    svg
      path
        stroke: $vivaGreenDark !important

    .forklift-compare-entrance-button--count
      display: block