.form-builder-full-view
  .message
    width: 100%
    text-align: center
    font-weight: bold
    padding: $paddingBase 0
    color: $c_white
    &.success
      background: $vivaGreenDark
    &.error
      background: $c_red
  form
    width: 100%
    display: flex
    flex-wrap: wrap
    > div
      width: 50%
      padding: $paddingBase
      &.full-width
        width: 100%
      label
        display: block
        padding-bottom: $paddingBase*0.5
      input, select, textarea
        width: 100%
        @include defaultFormElementStyle()
      input[type=checkbox]
        width: auto
      button[type=submit]
        @include buttonDefaultForButtonEl()
        width: 100%
      &.error
        input[type=text], textarea, select
          border-color: $c_red
        .error-message
          color: $c_red