.catalog-sorter
  display: flex
  @include smallest-view
  flex-wrap: wrap
  margin-top: $paddingBase*2
  > div
    width: 50%
    &.catalog-sorter__sorting-form
      text-align: right
      @include second-breakpoint
        width: 70%
      @include smallest-view
        width: 100%
        text-align: center
        padding-top: $paddingBase
      form
        position: relative
        top: -5px
        @include smallest-view
          top: 0
        select
          color: $vivaGreenDark
          background-color: $c_white
          //box-shadow: 0 5px 7px 0 rgba(160, 160, 160, 0.5)
          border: 1px solid $vivaGreenDark
          padding: $paddingBaseRem*0.25 $paddingBaseRem*0.5
          border-radius: $baseBorderRadius
          margin: 0 $paddingBase*0.5
        button[type=submit]
          @include buttonDefaultForButtonEl()
          //padding-top: $paddingBaseRem*0.25
          //padding-bottom: $paddingBaseRem*0.25
          box-shadow: none
          padding: 2px $paddingBase*2 1px $paddingBase*2
        button[type=submit],
        select
          font-size: 14px
          line-height: 24px
    &.catalog-sorter__view-style
      display: flex

      @include second-breakpoint
        width: 30%
      @include smallest-view
        width: 100%
        text-align: center
      a
        @include buttonDefault()
        width: 42px
        text-align: center
        margin: 0 5px 0 0
        &.selected
          background: $vivaYellow

        &.forklift-compare-entrance-button
          position: relative
          padding: 0
          width: 42px
          border: 1px solid $vivaGreenDark
          background: $c_white
          @include compareIconGreen()


          svg
            width: 35px
            display: block
            margin: 0 auto
            path
              stroke: $c_white !important


          .forklift-compare-entrance-button--count
            display: none
            position: absolute
            bottom: 0
            right: 3px
            color: $c_white
            font-weight: bold
            font-size: 13px

          &.in-compare
            background: $vivaGreenDark
            @include compareIconGreenFullFill()

            svg
              path
                stroke: $vivaGreenDark !important

            .forklift-compare-entrance-button--count
              display: block
