.image-align-right-without-padding
  float: right
.image-align-left-without-padding
  float: left
.image-align-left
  padding-bottom: $paddingBase*3
  padding-right: $paddingBase*3
  float: left
.image-align-right
  padding-bottom: $paddingBase*3
  padding-right: $paddingBase*3
  float: right
.four-same-columns
  display: flex
  flex-wrap: wrap
  > div
    width: 25%
  @include second-breakpoint
    > div
      width: 50%
      &:nth-child(1)
        order: 1
      &:nth-child(2)
        order: 3
      &:nth-child(3)
        order: 2
      &:nth-child(4)
        order: 4
  @include smallest-view
    > div
      width: 100%
      &:nth-child(1)
        order: 1
      &:nth-child(2)
        order: 2
      &:nth-child(3)
        order: 3
      &:nth-child(4)
        order: 4