.product-list-in-content
  display: flex
  flex-wrap: wrap
  .product-list-in-content__item
    background: $c_white
    width: 49%
    .product-list-in-content__item--inner
      padding: $paddingBase*2 $paddingBase*4
      border-right: 1px solid $c_lightGreen
      > p:last-of-type
        display: flex
        justify-content: space-between
    &:nth-of-type(2n+2)
      .product-list-in-content__item--inner
        border-right: none
    border-top: 1px solid $c_lightGreen
    margin: $paddingBase 0
    padding-top: $paddingBase*2
    &:nth-child(1),
    &:nth-child(2)
      padding-top: 0
      border-top: none
    @include third-breakpoint
      width: 100%
      &:nth-child(2)
        border-top: 1px solid $c_lightGreen
      .product-list-in-content__item--inner
        border-right: none
        padding: $paddingBase*2 $paddingBase*2
    img
      display: block
      max-width: 100%
      border: 1px solid $c_gray11
      &.product-list-in-content__item--gray-image
        filter: grayscale(100%)
    h2
      padding: $paddingBase*2 0
      font-size: 25px
      line-height: 31px
      min-height: 105px

    .product-list-in-content__item--in-stock-soon
      color: $vivaGreenDark
      font-size: 25px
      line-height: 31px
      font-weight: bold
      align-self: center

    .product-list-in-content__item--content
      min-height: 170px

      .product-list-in-content__item--content--parameter-equipment
        min-height: 100px

    p
      padding: 0
      margin: 0
    div
      &.price
        display: flex
        justify-content: space-between
        color: $vivaGreenDark
        font-size: 25px
        line-height: 31px
        font-weight: bold
        padding: $paddingBase*2 0 0 0
        > div
          &.price--vat-text
            width: 130px
        &.alone
          padding-bottom: $paddingBase*2
        &.gray
          color: $c_gray12
        &.small
          font-size: 20px
          line-height: 26px
          font-weight: normal
          padding: 0 0 $paddingBase*2 0
    button, a
      @include buttonDefaultForButtonEl()
      font-weight: bold
      font-size: 20px
      line-height: 30px
      margin-top: 0
      padding: $paddingBase $paddingBase*2
      text-transform: uppercase
      min-width: 130px
    a
      background: $c_red02
      color: $c_white