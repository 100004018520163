// Arrow button size.
$arrow-button-size: 2em !default;

// Arrow SVG size.
$arrow-size: 1.2em !default;

// Dots indicator size.
$dot-size: 8px !default;

// Height of a progress bar.
$progress-bar-height: 3px !default;