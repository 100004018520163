@mixin full-view
  @media (min-width: $fullViewBreakpoint+1)
    @content

@mixin first-breakpoint
  @media (max-width: $fullViewBreakpoint)
    @content

@mixin second-breakpoint
  @media (max-width: $firstBreakpoint)
    @content

@mixin third-breakpoint
  @media (max-width: $secondBreakpoint)
    @content

@mixin smallest-view
  @media (max-width: $smallestBreakpoint)
    @content

@mixin from-first-to-full-breakpoint
  @media (min-width: $firstBreakpoint+1) and (max-width: $fullViewBreakpoint)
    @content

@mixin from-second-to-first-breakpoint
  @media (min-width: $secondBreakpoint+1) and (max-width: $firstBreakpoint)
    @content

@mixin from-third-to-second-breakpoint
  @media (min-width: $smallestBreakpoint+1) and (max-width: $secondBreakpoint)
    @content

@mixin responsive-breakpoint-min($minWidth)
  @media (min-width: $minWidth)
    @content

@mixin responsive-breakpoint-max($value)
  @media (max-width: $value)
    @content