.sidebar-banners
  .sidebar-banners__item
    margin-bottom: $paddingBase*4.5
    border-left: 1px solid $c_lightGreen
    border-right: 1px solid $c_lightGreen
    border-radius: $baseBorderRadius
    a
      display: block
      text-decoration: none
      color: $c_gray6
      .sidebar-banners__headline
        background: $vivaGreenDark
        color: $c_white
        font-weight: bold
        font-size: 19px
        line-height: 29px
        text-transform: uppercase
        padding: $paddingBase+2 $paddingBaseRem*2 $paddingBaseRem*0.5 $paddingBaseRem*2
        border-top-left-radius: $baseBorderRadius
        border-top-right-radius: $baseBorderRadius
      img
        display: block
        margin: $paddingBase*2.5 0
        @include second-breakpoint
        margin-left: auto
        margin-right: auto
      .sidebar-banners_text,
      .sidebar-banners_button
        text-align: center
      .sidebar-banners_text
        padding-bottom: $paddingBase*1.5
        padding-left: $paddingBase*0.5
        padding-right: $paddingBase*0.5
      .sidebar-banners_button
        @include buttonDefault()
        border-radius: 0
        border-bottom-left-radius: $baseBorderRadius
        border-bottom-right-radius: $baseBorderRadius
      .sidebar-banners__headline,
      .sidebar-banners_text,
      .sidebar-banners_button
        display: block
      &:hover
        text-decoration: none
