.link-pdf-for-download-vertical,
.link-pdf-for-download-horizontal
  text-decoration: none
  color: $c_gray6

  span
    display: none

.link-pdf-for-download-vertical:before,
.link-pdf-for-download-horizontal:after
  @include addFarIcon($fa-var-file-pdf)
  margin-left: $paddingBase
  background: $vivaGreenDark
  color: $c_white
  border-radius: 4px
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
  font-size: 32px
  box-shadow: 0 5px 7px 0 rgba(160, 160, 160, 0.5)
  padding: $paddingBase $paddingBase*1.5
  &:hover
    color: $c_gray6
    text-decoration: none
    &:after
      background: $vivaYellow

.link-pdf-for-download-vertical
  text-align: center
  display: inline-block
  width: 120px

  &.multiple
    width: 70px

  &:before
    width: 60px
    margin: 0 auto $paddingBase*0.5 auto
    text-align: center
    display: block