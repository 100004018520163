@-webkit-keyframes spaceboots
  0%
    transform: translate(2px, 1px) rotate(0deg)
  10%
    transform: translate(-1px, -2px) rotate(-1deg)
  20%
    transform: translate(-3px, 0px) rotate(1deg)
  30%
    transform: translate(0px, 2px) rotate(0deg)
  40%
    transform: translate(1px, -1px) rotate(1deg)
  50%
    transform: translate(-1px, 2px) rotate(-1deg)
  60%
    transform: translate(-3px, 1px) rotate(0deg)
  70%
    transform: translate(2px, 1px) rotate(-1deg)
  80%
    transform: translate(-1px, -1px) rotate(1deg)
  90%
    transform: translate(2px, 2px) rotate(0deg)
  100%
    transform: translate(1px, -2px) rotate(-1deg)

@mixin spacebootsShake()
  animation-name: spaceboots
  animation-duration: 1.2s
  transform-origin: 50% 50%
  animation-iteration-count: infinite
  animation-timing-function: linear

.loading-forklift-ride
  display: none
  font-size: 24px
  font-weight: bold
  text-align: center
  color: $vivaGreenDark
  font-family: $fontFamilyRajdhani
  img
    margin-right: $paddingBase*2
    vertical-align: middle
    @include spacebootsShake()
  &.visible
    display: block
  &.white-background
    background: $c_white
  &.padding-20
    padding: $paddingBase*2 0