.person-info
  border-bottom: 10px solid $vivaGreenDark
  display: flex
  @include smallest-view()
    flex-wrap: wrap
  justify-content: space-between
  align-items: flex-end
  a
    text-decoration: none
    &:hover
      text-decoration: underline
  .person-info__image
    width: 40%
    img
      display: block
      height: 195px
      width: auto
    @include smallest-view()
      width: 100%
      height: 195px
      img
        max-height: 100%
        width: auto
        margin: 0 auto
  .person-info__text
    width: 58%
    @include smallest-view()
      width: 100%
    padding-bottom: $paddingBase*1.5
    i
      margin-right: $paddingBase*0.5
    .name
      font-size: 24px
      line-height: 29px
      padding-bottom: $paddingBase
    .position
      padding: $paddingBase*1.5 0
    .name,
    .position
      text-align: center
      font-weight: bold
